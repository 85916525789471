<template>
  <v-container>
    <v-card elevation="2" class="mt-12">
      <v-toolbar flat color="blue" dark>
        <v-toolbar-title>Export bonus file</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <template>
          <v-row>
            <v-col cols="3">
              <v-checkbox
                v-model="checkStartFinishTrack"
                label="Start to finish track"
              ></v-checkbox
            ></v-col>
            <v-col cols="3">
              <v-checkbox
                v-model="checkComboTrack"
                label="Combo bonus tracks"
              ></v-checkbox
            ></v-col>
            <v-col cols="3">
              <v-checkbox
                v-model="checkDistanceCircles"
                label="Distance circles"
              ></v-checkbox
            ></v-col> </v-row
        ></template>
        <v-btn
          color="pink white--text"
          :href="exportBonusGpxFile('SMLX')"
          small
          download="bonuslocations-ALL.gpx"
          ><v-icon left dark> mdi-cloud-download </v-icon> ALL bonuses
        </v-btn>
        <v-btn
          class="ml-5"
          color="pink white--text"
          small
          :href="exportBonusGpxFile('S')"
          download="bonuslocations-S.gpx"
          ><v-icon left dark> mdi-cloud-download </v-icon> S bonuses
        </v-btn>
        <v-btn
          class="ml-5"
          color="pink white--text"
          small
          :href="exportBonusGpxFile('M')"
          download="bonuslocations-M.gpx"
          ><v-icon left dark> mdi-cloud-download </v-icon> M bonuses
        </v-btn>
        <v-btn
          class="ml-5"
          color="pink white--text"
          small
          :href="exportBonusGpxFile('L')"
          download="bonuslocations-L.gpx"
          ><v-icon left dark> mdi-cloud-download </v-icon> L bonuses
        </v-btn>
        <v-btn
          class="ml-5"
          color="pink white--text"
          small
          :href="exportBonusGpxFile('X')"
          download="bonuslocations-XL.gpx"
          ><v-icon left dark> mdi-cloud-download </v-icon> XL bonuses
        </v-btn>
      </v-card-text>
    </v-card>
    <v-card elevation="2" class="mt-12">
      <v-toolbar flat color="blue" dark>
        <v-toolbar-title>Export planned route</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-btn
          color="pink white--text"
          :href="exportRouteGpxFile()"
          small
          download="route.gpx"
          ><v-icon left dark> mdi-cloud-download </v-icon>route file</v-btn
        >
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'FileExport',
  data() {
    return {
      checkStartFinishTrack: true,
      checkComboTrack: true,
      checkDistanceCircles: true,
      trackColors: [
        'Black',
        'DarkRed',
        'DarkGreen',
        'DarkBlue',
        'DarkMagenta',
        'DarkCyan',
        'Red',
        'Blue'
      ]
    }
  },
  computed: {
    ...mapState({
      CurrentUser: (state) => state.moduleUser.CurrentUser,
      UserProfile: (state) => state.moduleUser.UserProfile,
      Rallies: (state) => state.moduleRallies.Rallies,
      BonusLocations: (state) => state.moduleBonusLocations.BonusLocations,
      RouteStops: (state) => state.moduleRouteStops.RouteStops,
      Preferences: (state) => state.modulePreferences.Preferences
    }),
    ...mapGetters('moduleBonusLocations', [
      'BonusLocationsExcludingNonRidingGetter',
      'BonusLocationsCombosConstituentsGetter',
      'BonusLocationsByNameGetter'
    ]),
    ...mapGetters('moduleRouteStops', ['RouteStopsSortedByOrder']),
    activeRally() {
      const item = this.Rallies.find(
        (rally) => rally.rallyid === this.UserProfile.activerallyid
      )
      return item
    }
  },
  methods: {
    exportRouteGpxFile() {
      let xmlFileBuilder = require('xmlbuilder')
      let scaffoldGpxFile = {
        gpx: {
          '@xmlns': 'http://www.topografix.com/GPX/1/1',
          '@xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance',
          '@xmlns:trp': 'http://www.garmin.com/xmlschemas/TripExtensions/v1',
          '@xsi:schemaLocation':
            'http://www.topografix.com/GPX/1/1 http://www.topografix.com/GPX/1/1/gpx.xsd http://www.garmin.com/xmlschemas/TripExtensions/v1 http://www.garmin.com/xmlschemas/TripExtensionsv1.xsd',
          '@creator': 'LD Rally and Ride Planner',
          '@version': '1.1',
          '@author': 'Patrick Nijsters',
          rte: {
            name: `Leg ${this.activeRally.legid} of ${this.activeRally.rallyname}`,
            extensions: {
              'trp:Trip': {
                'trp:TransportationMode': 'Motorcycling'
              }
            },
            rtept: []
          }
        }
      }
      for (let index in this.RouteStopsSortedByOrder) {
        let routestop = {
          '@lat': this.BonusLocationsByNameGetter(
            this.RouteStopsSortedByOrder[index].name
          ).latitude,
          '@lon': this.BonusLocationsByNameGetter(
            this.RouteStopsSortedByOrder[index].name
          ).longitude,
          name: this.BonusLocationsByNameGetter(
            this.RouteStopsSortedByOrder[index].name
          ).longname,
          cmt: this.BonusLocationsByNameGetter(
            this.RouteStopsSortedByOrder[index].name
          ).comment,
          desc: this.BonusLocationsByNameGetter(
            this.RouteStopsSortedByOrder[index].name
          ).description,
          sym: this.BonusLocationsByNameGetter(
            this.RouteStopsSortedByOrder[index].name
          ).symbol,
          extensions: {
            'trp:ViaPoint': {
              'trp:CalculationMode': 'FasterTime',
              'trp:ElevationMode': 'Standard'
            }
          }
        }
        scaffoldGpxFile.gpx.rte.rtept.push(routestop)
      }

      let feed = xmlFileBuilder.create(scaffoldGpxFile, { encoding: 'utf-8' })
      return (
        'data:Application/octet-stream,' +
        encodeURIComponent(feed.end({ pretty: true }))
      )
    },
    exportBonusGpxFile(_tshirtselection) {
      let xmlFileBuilder = require('xmlbuilder')
      let bonusLocation = {}
      let comboTrack = {}
      let startfinish = {}

      let scaffoldGpxFile = {
        gpx: {
          '@xmlns': 'http://www.topografix.com/GPX/1/1',
          '@xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance',
          '@xmlns:wptx1':
            'http://www.garmin.com/xmlschemas/WaypointExtension/v1',
          '@xmlns:gpxx': 'http://www.garmin.com/xmlschemas/GpxExtensions/v3',
          '@xmlns:trp': 'http://www.garmin.com/xmlschemas/TripExtensions/v1',
          '@xmlns:gpxtpx':
            'http://www.garmin.com/xmlschemas/TrackPointExtension/v1',
          '@xsi:schemaLocation':
            'http://www.topografix.com/GPX/1/1 http://www.topografix.com/GPX/1/1/gpx.xsd http://www.garmin.com/xmlschemas/WaypointExtension/v1 http://www8.garmin.com/xmlschemas/WaypointExtensionv1.xsd http://www.garmin.com/xmlschemas/GpxExtensions/v3 http://www8.garmin.com/xmlschemas/GpxExtensionsv3.xsd http://www.garmin.com/xmlschemas/TripExtensions/v1 http://www.garmin.com/xmlschemas/TripExtensionsv1.xsd',
          '@creator': 'LD Rally and Ride Planner',
          '@version': '1.1',
          '@author': 'Patrick Nijsters',
          wpt: [],
          trk: []
        }
      }
      let colorcounter = 0

      let selectedBonus = null
      selectedBonus = this.BonusLocationsExcludingNonRidingGetter.filter(
        function (bonus) {
          return (
            (bonus.value === null) |
            _tshirtselection.includes(
              String(bonus.getTshirtsize()).substring(0, 1)
            )
          )
        }
      )

      for (let index in selectedBonus) {
        if (
          (selectedBonus[index].category === 'A') |
          (selectedBonus[index].category === 'D') |
          (selectedBonus[index].category === 'T') |
          (selectedBonus[index].category === 'S')
        ) {
          let proximityValue = 0
          if (selectedBonus[index].proximity) {
            proximityValue = this.Preferences.routing.cluster_proximity * 1609
          }

          bonusLocation = {
            '@lat': selectedBonus[index].latitude,
            '@lon': selectedBonus[index].longitude,
            name: selectedBonus[index].longname,
            cmt: selectedBonus[index].comment,
            desc: selectedBonus[index].description,
            sym: selectedBonus[index].symbol,
            extensions: {
              'wptx1:WaypointExtension': {
                'wptx1:Proximity': proximityValue,
                'wptx1:DisplayMode': 'SymbolAndName',
                'wptx1:Categories': {
                  'wptx1:Category': this.activeRally.garmincategory
                },
                'wptx1:Address': {
                  'wptx1:StreetAddress': selectedBonus[index].comment,
                  'wptx1:City': 'sunrise ' + selectedBonus[index].sunrise,
                  'wptx1:State': '',
                  'wptx1:Country': '',
                  'wptx1:PostalCode': ''
                },
                'wptx1:PhoneNumber': 'sunset ' + selectedBonus[index].sunset
              }
            }
          }
          scaffoldGpxFile.gpx.wpt.push(bonusLocation)
        }

        // Save the START and END bonuses separetely since we need them later to create a START to FINISH track
        // Add distance circles to START and END
        if (selectedBonus[index].name === 'START') {
          startfinish.startlatitude = selectedBonus[index].latitude
          startfinish.startlongitudegitude = selectedBonus[index].longitude

          let distancecircles = []
          distancecircles.push({
            name: `START-${this.Preferences.routing.distance_1_start_radius}`,
            proximity: this.Preferences.routing.distance_1_start_radius * 1609
          })
          distancecircles.push({
            name: `START-${this.Preferences.routing.distance_2_start_radius}`,
            proximity: this.Preferences.routing.distance_2_start_radius * 1609
          })
          distancecircles.push({
            name: `START-${this.Preferences.routing.distance_3_start_radius}`,
            proximity: this.Preferences.routing.distance_3_start_radius * 1609
          })

          for (index in distancecircles) {
            bonusLocation = {
              '@lat': startfinish.startlatitude,
              '@lon': startfinish.startlongitudegitude,
              name: distancecircles[index].name,
              sym: 'Waypoint',
              extensions: {
                'wptx1:WaypointExtension': {
                  'wptx1:Proximity': distancecircles[index].proximity,
                  'wptx1:DisplayMode': 'SymbolOnly'
                }
              }
            }
            if (this.checkDistanceCircles === true)
              scaffoldGpxFile.gpx.wpt.push(bonusLocation)
          }
        }
        if (selectedBonus[index].name === 'END') {
          startfinish.finishlat = selectedBonus[index].latitude
          startfinish.finishlon = selectedBonus[index].longitude

          let distancecircles = []
          distancecircles.push({
            name: `END-${this.Preferences.routing.distance_1_end_radius}`,
            proximity: this.Preferences.routing.distance_1_end_radius * 1609
          })
          distancecircles.push({
            name: `END-${this.Preferences.routing.distance_2_end_radius}`,
            proximity: this.Preferences.routing.distance_2_end_radius * 1609
          })
          distancecircles.push({
            name: `END-${this.Preferences.routing.distance_3_end_radius}`,
            proximity: this.Preferences.routing.distance_3_end_radius * 1609
          })

          for (index in distancecircles) {
            bonusLocation = {
              '@lat': startfinish.finishlat,
              '@lon': startfinish.finishlon,
              name: distancecircles[index].name,
              sym: 'Waypoint',
              extensions: {
                'wptx1:WaypointExtension': {
                  'wptx1:Proximity': distancecircles[index].proximity,
                  'wptx1:DisplayMode': 'SymbolOnly'
                }
              }
            }
            if (this.checkDistanceCircles === true)
              scaffoldGpxFile.gpx.wpt.push(bonusLocation)
          }
        }

        // Determine the combo bonuses and create a track for each
        if (
          (selectedBonus[index].category === 'AC') |
          (selectedBonus[index].category === 'DC') |
          (selectedBonus[index].category === 'TC')
        ) {
          let trackpoints = []
          let counter = 0
          for (let index2 in this.BonusLocationsCombosConstituentsGetter) {
            if (
              this.BonusLocationsCombosConstituentsGetter[index2].comboname ===
              selectedBonus[index].name
            ) {
              trackpoints[counter] = {
                '@lat': this.BonusLocationsByNameGetter(
                  this.BonusLocationsCombosConstituentsGetter[index2].name
                ).latitude,
                '@lon': this.BonusLocationsByNameGetter(
                  this.BonusLocationsCombosConstituentsGetter[index2].name
                ).longitude
              }
              counter = counter + 1
            }
          }
          comboTrack = {
            name: selectedBonus[index].longname,
            cmt: selectedBonus[index].comment,
            desc: selectedBonus[index].description,
            extensions: {
              'gpxx:TrackExtension': {
                'gpxx:DisplayColor': this.trackColors[colorcounter]
              }
            },
            trkseg: {
              trkpt: trackpoints
            }
          }
          if ((this.checkComboTrack === true) & (_tshirtselection === 'SMLX')) {
            scaffoldGpxFile.gpx.trk.push(comboTrack)
          }
          colorcounter = colorcounter + 1
          if (colorcounter > this.trackColors.length - 1) {
            colorcounter = 0
          }
        }
      }
      // Create START to FINISH track
      let startfinishTrack = {
        name: 'START to FINISH',
        cmt: 'START to FINISH',
        desc: 'START to FINISH',
        extensions: { 'gpxx:TrackExtension': { 'gpxx:DisplayColor': 'Black' } },
        trkseg: {
          trkpt: [
            {
              '@lat': startfinish.startlatitude,
              '@lon': startfinish.startlongitudegitude
            },
            {
              '@lat': startfinish.finishlat,
              '@lon': startfinish.finishlon
            }
          ]
        }
      }
      if (this.checkStartFinishTrack === true) {
        scaffoldGpxFile.gpx.trk.push(startfinishTrack)
      }
      let feed = xmlFileBuilder.create(scaffoldGpxFile, { encoding: 'utf-8' })
      return (
        'data:Application/octet-stream,' +
        encodeURIComponent(feed.end({ pretty: true }))
      )
    }
  }
}
</script>

<style></style>
